<template>
  <v-container>
    <v-card class="transparent" flat>
      <v-card-title class="title font-weight-bold">
        <span class=""> 개발자 도구 </span>
      </v-card-title>
      <v-card-subtitle>
        시스템에 심각한 장애를 초래할 수 있습니다.
      </v-card-subtitle>
      <v-card-actions>
        <v-btn @click="test">test</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<style scoped></style>

<script>
import API from "@/API/DevTest.js";

export default {
  data() {
    return {};
  },
  methods: {
    async test() {
      // this.$gtag.event("sign_up", {
      //   event_category: "Engagement",
      //   event_label: "SignUp",
      //   value: 1,
      // });
      // console.log("here");
      API.test();
    },
  },
};
</script>
