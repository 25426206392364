import Vue from "vue";
// import store from "../store";
// const auth = Vue.prototype.$firebase.auth();

export default {
  async test() {
    console.log("API Called - DevTest.js");

    return Vue.prototype.$axios.get("develop/test");
  },
};
